<template>
  <div class="account">
    <div class="left">
      <div class="menus">
        <a-menu
          v-model:selectedKeys="selectedKeys"
          style="width: 250px"
          mode="inline"
        >
          <a-menu-item key="/account/info" @click="navigate('/account/info')">
            <template #icon>
              <UserOutlined />
            </template>
            个人信息
          </a-menu-item>
        </a-menu>
      </div>
    </div>
    <div class="right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { UserOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    UserOutlined
  },
  setup () {
    const selectedKeys = ref([])

    return {
      selectedKeys
    }
  },

  created () {
    // 监听路由变化，更新页面路由信息
    this.$watch(
      () => this.$route,
      () => {
        this.updateRoute()
      },
      { immediate: true }
    )
  },

  methods: {
    navigate (path) {
      this.$router.push({ path })
    },

    updateRoute () {
      const { path } = this.$route
      this.selectedKeys = [path]
    }
  }
})
</script>

<style lang="less" scoped>
.account {
  width: 100%;
  height: 100%;
  padding-bottom: 9px;
  position: relative;
  box-sizing: border-box;
  padding-left: 250px;
  .left {
    width: 250px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-bottom: 9px;
    box-sizing: border-box;
    .menus {
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 10px 0 0 10px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      box-sizing: border-box;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .right {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 0 10px 10px 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
}
</style>
